var Bearer = false //分布式
import axios from 'axios'
import Qs from 'qs'
axios.defaults.withCredentials = true
axios.defaults.timeout = 60000
import { showLoading, hideLoading } from '@/common/loading.js';
axios.interceptors.response.use((res) => {
    return res.data || res;
  }, (error) => {
	  alert("请求失败")
	  hideLoading()
    return Promise.reject(error);
  });

function postAjaxfile(url,params){
    var  headerrel = {
        'Content-Type': 'multipart/form-data',
    }
    var paramrel = params
    headerrel["Authorization"] = Bearer?"Bearer ":""+sessionStorage.getItem('token');
    return axios.post(url,paramrel,{
        headers: headerrel
    });
}
function postAjaxForm(url,params){
    var  headerrel = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    var paramrel = Qs.stringify(params)
    headerrel["Authorization"] = Bearer?"Bearer ":""+sessionStorage.getItem('token');
    return axios.post(url,paramrel,{
        headers: headerrel
    });
}
function getAjaxForm(url,params){
    return axios.get(url,{
        source: 'h5',
        userId: localStorage.getItem('userId'),
        sid: localStorage.getItem('sid'),
        data: params
    },{
        headers: {'Content-Type': 'application/x-www-form-urlencoded','token': sessionStorage.getItem('token')}
    });
}
function postAjaxJson(url,params){
    var  headerrel = {
        'Content-Type': 'application/json'
    }
	headerrel["Authorization"] = Bearer?"Bearer ":""+sessionStorage.getItem('token');
    return axios.post(url,params,{
        headers: headerrel
    });
}

function postAjaxformLogin(url,params){
    var  headerrel = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    var paramrel = Qs.stringify(params)
    return axios.post(url,paramrel,{
        headers: headerrel
    }); 
}

function postAjaxJsonexcel(url,params){
    var  headerrel = {
        'content-type': 'application/json'
    }
    headerrel["Authorization"] = Bearer?"Bearer ":""+sessionStorage.getItem('token');
    return axios.post(url,params,{
        headers: headerrel,
        responseType: 'blob'
    });
}
function getAjaxJson(url,params){
    return axios.get(url,params,{
        headers: {'Content-Type': 'application/json','Authorization': Bearer?"Bearer ":""+sessionStorage.getItem('token')}
    });
}

//表单提交
function postForm(url,params,callbak,errorback){
	params["appid"] = appid
	params["appId"] = appid
	// console.log(params)
	postAjaxformLogin(BASEURL+url,params).then(res => {
		 console.log(res);
		if (res.code === 200) {
		  callbak(res)
		} else {
		  alert(res.message)
		  errorback(res)
		}
	})
}
//JSON提交请求
function postJson(url,params,callbak,errorback){
	showLoading();
	params["appid"] = appid
	params["appId"] = appid
	postAjaxJson(BASEURL+url,params).then(res => {
		console.log(res);
		hideLoading();
		if (res.code === 200) {
		  callbak(res)
		}else {
		   alert(res.message)
	       errorback(res)
		}
	})    
}
//JSON提交请求
function postJsonFile(url,params,callbak){
	postAjaxfile(BASEURL+url,params).then(res => {
		console.log(res);
		callbak(res)
		// if (res.code === 200) {
		//   callbak(res)
		// } else if (res.code === 500) {
		//   alert(res.message)
		// } else {
		//   alert(res.message)
		// }
	})    
}
function postJsonexcel(url,params,callbak){
	postAjaxJsonexcel(BASEURL+url,params).then(res => {
		console.log(res);
		if (res) {
		  callbak(res)
		} else if (res.code === 500) {
		  alert(res.message)
		} else {
		  alert(res.message)
		}
	})    
}
var BASEURL = process.env.VUE_APP_BASEURL
var appid = "hlax";

export default {
    // 全局域名npm
    gethttp(){
        return BASEURL;
    },
    postForm,
	postJson,
	postJsonFile,//导入
	postJsonexcel,//导出表单
	/**
	* 阿里云OSS上传 UploadFile  UploadFileOSS
	*/
	uploadHttp(file,ossClient) {
		var _this = this
		// console.log("文件上传")
		showLoading();
		const data = new FormData();
		data.append('file', file);
		return new Promise((resolve, reject) => {
			var state = true //是否请求超时
			const res = postAjaxfile(_this.gethttp()+"UploadFileManager/UploadFileOSS",data)
			// console.log("文件上传返回",res)
			var result = res.then((v)=>{
				hideLoading();
				state = false
				console.log("文件上传返回",v)
				if(v&&v.code==200){
					var obj = {}
					var list = []
					list.push(v.data)
					obj["requestUrls"] = list
					obj["status"] = 200
					resolve(obj)
				}else{
					resolve(false)
				}
			})
			setTimeout(function () {// 设定定时器，超时8S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
				if(state){
					hideLoading(); // 关闭遮罩层
					resolve(false)
				}
			},60000)
			
		})
		//oss对象存储上传文件
		// return new Promise((resolve, reject) => {
		// 	const fileName = "qql_"+new Date().getTime();  //定义唯一的文件名
		// 	ossClient.put(fileName, file).then(({res, url, name}) => {
		// 		console.log(`阿里云OSS上传文件成功回调`, res, url, name);
		// 		resolve(res)
		// 	}).catch((err) => {
		// 		resolve(err)
		// 	    console.log(`阿里云OSS上传文件失败回调`, err);
		// 	});
		// })
	},
	/**
	* 阿里云OSS上传 office文件
	*/
	uploadOfficeHttp(file) {
		var _this = this
		console.log("文件上传")
		showLoading();
		const data = new FormData();
		data.append('multipartFile', file);
		return new Promise((resolve, reject) => {
			var state = true //是否请求超时
			const res = postAjaxfile(_this.gethttp()+"/UploadFileManager/UploadOffice2Html",data)
			// console.log("文件上传返回",res)
			var result = res.then((v)=>{
				hideLoading();
				state = false
				console.log("文件上传返回",v)
				if(v&&v.code==200){
					var obj = {}
					var list = []
					list.push(v.data)
					obj["requestUrls"] = list
					obj["status"] = 200
					resolve(obj)
				}else{
					resolve(false)
				}
			})
			setTimeout(function () {// 设定定时器，超时8S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
				if(state){
					hideLoading(); // 关闭遮罩层
					resolve(false)
				}
			},60000)
			
		})
	},
	/**
	* 阿里云OSS上传 video视频文件
	*/
	uploadvideoHttp(file) {
		//10分钟
		axios.defaults.timeout = 600000
		var _this = this
		console.log("文件上传")
		showLoading();
		const data = new FormData();
		data.append('multipartFile', file);
		return new Promise((resolve, reject) => {
			var state = true //是否请求超时
			const res = postAjaxfile(_this.gethttp()+"/UploadFileManager/UploadVideoM3U8",data)
			// console.log("文件上传返回",res)
			var result = res.then((v)=>{
				hideLoading();
				state = false
				console.log("文件上传返回",v)
				if(v&&v.code==200){
					var obj = {}
					var list = []
					list.push(v.data)
					obj["requestUrls"] = list
					obj["status"] = 200
					resolve(obj)
				}else{
					resolve(false)
				}
			})
			setTimeout(function () {// 设定定时器，超时8S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
				if(state){
					hideLoading(); // 关闭遮罩层
					resolve(false)
				}
			},600000)
			
		})
	},
   
}