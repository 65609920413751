<template>
	<div class="sysimagebox" v-if="src&&isshowimg">
		<img :src="src" class="img100" @error="handleEvente"></img>
		<img  class="shuiyin" :src="require('@/assets/shuiyin.png')"></img>
	</div>
</template>
<script>
export default {
	name: 'myImg',
	props: {
		classname: {
			// 按钮尺⼨
			type: String,
			default: ''
		},
		width: {
			// 按钮尺⼨
			type: String,
			default: '78'
		},
		height: {
			// 按钮尺⼨
			type: String,
			default: '78'
		},
		src: {
			// 按钮尺⼨
			type: String,
			default: ''
		},
		isshow: {
			//关闭还是显示按钮
			type: Boolean,
			default: true
		}
	},
	watch:{
		src: {
			immediate:true,
			handler(newVal, objVal) {
			    this.isshowimg = true
			}
	    }
	},
	data() {
		return {
			isshowimg:true
		};
	},
	methods: {
		handleEvente(e){
			console.log("img fail")
			this.isshowimg = false
		},
	},
	mounted() {}
};
</script>

<style scoped>
	.sysimagebox{
		width: 100%;
		height: 100%;
		position: relative;
	}
	.sysimagebox .shuiyin{
		width: 40%;
		height: auto;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 5;
	}
</style>
