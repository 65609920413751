import netUtil from './http.js'

//通用
function commonFuc(url,params){
    return new Promise((resolve, reject) => {
       netUtil.post(url, params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
			}
		})
    })
}
// 单词消词
function userSuccessWord(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userSuccessWord/save", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

// 单词消词list
function userSuccessWordlist(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userSuccessWord/saveList", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

// 添加单词到复习库或重置阶段
function userReviewWord(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userReviewWord/save", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

//保存收藏和不收藏
function userCollectBooksWord (params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userCollectBooksWord/save", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}
//错词
function userErrWord (params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userErrWord/save", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

// 删除消磁
function deletefailWord(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userSuccessWord/deleteWord", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

//复习单词
function updateWordStage(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userReviewWord/updateWordStage", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}
// 全局化
export default {
  commonFuc,
  userCollectBooksWord,
  userSuccessWord,
  userSuccessWordlist,
  userReviewWord,
  userErrWord,
  deletefailWord,
  updateWordStage,
}