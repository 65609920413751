<template>
	<!-- 菜单 -->
	<div id="header" class="header">
		<div class="my-container">
			<div class="block20"></div>
			<div class="logo">
				<img class="img100" src="../../assets/logo/pc_logo_h.png" />
			</div>
			<div class="block10"></div>
			<div class="navbar_box">
				<div class="nav-item" @click="menuClick(index, item.url)" :class="curpage == item.url ? 'cur_tab' : ''"
					v-for="(item, index) in menulist" :key="item.index">
					<img class="icon" :src="item.img" />
					<div class="text fontsize16">{{ item.menu }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	export default {
		name: "menuBar",
		data() {
			return {
				current_index: 0, // 当前索引
				menulist: [{
						index: 0,
						menu: "首页",
						url: "home",
						img: require("../../assets/logo/Frame.png"),
					},
					{
						index: 1,
						menu: "个人信息",
						url: "mine",
						img: require("../../assets/logo/Frame1.png"),
					},
					{
						index: 2,
						menu: "全部词书",
						url: "allOrMyBooks",
						img: require("../../assets/logo/Frame2.png"),
					},
					{
						index: 3,
						menu: "单元家听",
						url: "beforeListen",
						img: require("../../assets/logo/Frame3.png"),
					},
					{
						index: 4,
						menu: "查单词",
						url: "searchWord",
						img: require("../../assets/logo/Frame4.png"),
					},
					{
						index: 5,
						menu: "河狸筑坝",
						url: "game",
						img: require("../../assets/logo/Frame5.png"),
					},
					{
						index: 6,
						menu: "我的订单",
						url: "myorder",
						img: require("../../assets/logo/Frame6.png"),
					},
					{
						index: 7,
						menu: "关于我们",
						url: "aboutus",
						img: require("../../assets/logo/Frame7.png"),
					},
				],
				bgnum: 1, //透明度
				fontsize: "中",
			};
		},
		computed: {
			...mapState(["userInfo", "isLogin", "curpage", "sysRole"]),
		},
		created() {
			// 此处true需要加上，不加滚动事件可能绑定不成功
			//window.addEventListener("scroll", this.handleScroll, true);
		},
		methods: {
			...mapMutations(["SET_USERINFO", "SET_ISLOGIN"]),
			//退出
			outLogin() {
				var _this = this;
				this.$confirm("确认要退出登录嘛？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						_this.$message.success("退出登录成功！");
						sessionStorage.setItem("token", "");
						_this.SET_ISLOGIN(false);
						_this.SET_USERINFO({});
						//保存
						sessionStorage.setItem("key_state", "");
						_this.$util.routerPath("/");
					})
					.catch(() => {});
			},
			//字体大小
			handcommand2(fontsize) {
				if (fontsize == "small") {
					this.fontsize = "小";
				} else if (fontsize == "middle") {
					this.fontsize = "中";
				} else {
					this.fontsize = "大";
				}
				//基数是16，
				// $(".fontsize14").css("font-size",`${fontsize}px`)
				// $("html").setAttribute("data-size","middle")
				window.document.documentElement.setAttribute("data-size", fontsize);
			},
			//滚动
			handleScroll() {
				var scrolltop =
					document.documentElement.scrollTop || document.body.scrollTop;
				if (scrolltop < 368) {
					this.bgnum = scrolltop / 368;
				} else {
					this.bgnum = 1;
				}
			},
			menuClick(index, url) {
				//console.log("我点击了menu");
				if (url) {
					this.current_index = index; // 设置点击后的下标
					this.$emit("menuClick", url);
					this.$router.push({
						name: url,
					});
				} else {
					this.$message.info("此模块暂未开放");
				}
			},
			//处理参数
			parseString(url) {
				const obj = {};
				if (url && url.split("?").length > 1) {
					const params = url.split("?")[1].split("&");
					params.map((item) => (obj[item.split("=")[0]] = item.split("=")[1]));
				}
				console.log(obj);
				return obj;
			},
			//跳转搜索
			handleSearche(url) {
				if (!this.isLogin) {
					this.$router.push({
						path: "/login",
					});
					return;
				}
				if (url) {
					if (url.indexOf("http://") > -1 || url.indexOf("https://") > -1) {
						this.$util.windowOpen(url);
					} else {
						const newUrl = url.split("?")[0];
						const params = this.parseString(url);
						this.$router.push({
							path: "/" + newUrl,
							query: params,
						});
					}
				} else {
					console.log("无跳转路径");
					this.$message.info("此模块暂未开放");
				}
			},
		},
	};
</script>
<style></style>