import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//为了方便测试，此处用vuex做全局数据
const store = new Vuex.Store({
	state: {
		bsnName:"河狸爱学",
		userInfo: {},
		isLogin: false,//是否登录
		remark: '',
		curpage:"home",
		sysRole:"heli",
		//{ key: '', value: '' }
		myReadingObj:null,//我正在学习
		classLevel:[],//阶段
		//innerAudioContext:uni.createInnerAudioContext(),//音频
		studyingData:{
			detailwordList:[],//详情集合
			wordoptions:{},//答案选项集合
			todaywords:[],
			oriwords:[],
			curnum:0,//当前学到第几个
			firstwords:[],//第一次推送的词
			secondwords:[],//第二次的词
			thirdwords:[],//第二次的词
			failwords:[],//错误的放到这里，返回第一关
			successwords:[],//完成的反这里
		},//新学习

	},
	mutations: {
		SET_Data(state, { key, value }) {
			// console.log(key)
			// console.log(value)
			state[key] = value
			sessionStorage.setItem('key_state',JSON.stringify(state))
		},
		SET_CURPAGE(state, curpage) {
			//console.log(curpage)
			state.curpage = curpage
		},
		SET_REMARK(state, remark) {
			state.remark = remark
		},
		SET_USERINFO(state, userInfo) {
			// console.log(userInfo)
			state.userInfo = userInfo
		},
		SET_ISLOGIN(state, isLogin) {
			//console.log(isLogin)
			state.isLogin = isLogin
		}
	}
})

export default store
