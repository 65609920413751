<template>
	<!-- 底部 -->
    <div class="index_footer">
		<div class="foot_con fontsize16">
			<div class="w1200 hxinfo">
				<div class="hxinfo_box">
					<!-- <div class="info">
						<div class="text fontsize24">苏州记忆曲线科技有限公司</div>
						<div class="textdiv">
							<img class="img" src="../../assets/static/Frame.png"/>
							<div class="text1">联系电话：18112612925</div>
						</div>
					</div> -->
					<div class="info_two">
						<a href="http://beian.miit.gov.cn/" target="_blank">备案号：苏ICP备2024083895号</a>
						<!-- <div class="text">地址： 江苏省苏州市常熟市莫城街道长浜路201号</div> -->
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
export default {
	name: 'Footer',
	data() {
		return {
			current: 0, // 当前索引
			dateyear: ''
		};
	},
	created() {
		this.dateyear = new Date().getFullYear();
	},
	methods: {
		See(e) {
			window.location.href = e;
		}
	}
};
</script>
<style type="text/css" scoped>

</style>
